import React, { useMemo } from "react";
import { track } from "@vercel/analytics";

interface CasinoCardProps {
  logo: string;
  name: string;
  index: number; // Index of the item
  totalItems: number; // Total items in the list
  votes: number;
  bonus: string;
  deposit: string;
  link?: string;
  exclusiveText?: string;
}

const CasinoCard: React.FC<CasinoCardProps> = ({
  logo,
  name,
  index,
  totalItems,
  votes,
  bonus,
  deposit,
  link,
  exclusiveText,
}) => {
  // Calculate rating based on index and totalItems
  const calculatedRating = useMemo(() => {
    const maxRating = 9.9;
    const minRating = 9.1;

    if (totalItems <= 3) {
      // Special case for 3 items
      const ratings = [9.9, 9.8, 9.7];
      return ratings[index].toFixed(1);
    }

    if (totalItems <= 1) {
      // Single item gets max rating
      return maxRating.toFixed(1);
    }

    // Linear distribution for more than 3 items
    const step = (maxRating - minRating) / (totalItems - 1);
    return (maxRating - step * index).toFixed(1);
  }, [index, totalItems]);

  // Extract and append gclid value to the end of the URL
  const updatedLink = useMemo(() => {
    if (!link) return "#"; // Fallback if no link provided
    const params = new URLSearchParams(window.location.search);
    const gclid = params.get("gclid");
    return gclid ? `${link}${gclid}` : link; // Append gclid if present
  }, [link]);
  console.log("exclusiveText", exclusiveText);
  return (
    <div className="bg-[#2a2a2a] mb-3 text-white p-6 rounded-xl transition-shadow border border-gray-700 w-full flex flex-col justify-between mx-auto relative">
      {exclusiveText && <div className="ribbon">{exclusiveText}</div>}

      {/* Logo and Name Section */}
      <div className="flex flex-col items-center mb-4">
        <img
          src={logo}
          alt={name}
          className="w-full h-44 object-contain rounded-md mb-4"
        />
        <h3 className="text-2xl font-semibold text-white text-center">
          {name}
        </h3>
        <span className="bg-yellow-400 text-black text-xs font-bold px-3 py-1 rounded-full uppercase mt-2">
          Top Casino
        </span>
      </div>

      {/* Bonus Section */}
      <div className="bg-gray-800 p-4 rounded-lg mb-4 text-center">
        <p className="text-2xl font-bold text-green-400">{bonus}</p>
        <p className="text-lg font-bold text-red-400">
          Depósito Mínimo: {deposit}
        </p>
      </div>

      {/* Rating and Votes */}
      <div className="flex items-center justify-between mb-4 text-center">
        <div className="flex items-center space-x-1">
          <span className="text-2xl font-bold text-yellow-500">
            {calculatedRating}/10
          </span>
          <span className="text-sm text-gray-400">Our Score</span>
        </div>
        <div className="flex items-center text-sm text-gray-400">
          <span className="mr-1">⭐</span>
          <span>{votes} votes</span>
        </div>
      </div>

      {/* Payment Methods */}
      <div className="flex justify-center space-x-4 text-gray-400 mb-4">
        <span className="text-lg">💳</span>
        <span className="text-lg">🍎</span>
        <span className="text-lg">💵</span>
        <span className="text-lg">💳</span>
      </div>

      {/* Get Bonus Button */}
      <a
        href={updatedLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          track("Visit", { name });
        }}
      >
        <button className="w-full py-3 bg-yellow-500 text-black font-semibold rounded-lg hover:bg-yellow-600 transition-colors">
          Get Bonus
        </button>
      </a>
    </div>
  );
};

export default CasinoCard;
