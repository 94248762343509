// pages/Home.tsx
import React from "react";
import Hero from "../components/Hero/Hero";
import Casinos from "../components/Casinos/Casinos";
import OnlineCasinosInfo from "../components/OnlineCasinoInfo/OnlineCasinoInfo";
import CookieConsent from "../components/Cookie/Cookie";
import NewAddedText from "../components/NewAddedText";
import ThreeCasinos from "../components/3casinos/ThreeCasinos";

const Home: React.FC = () => {
  return (
    <div>
      <Hero
        title={
          "Os 10 principais cassinos online em Portugal - 4 de novembro de 2024"
        }
      />
      <Casinos />
      <OnlineCasinosInfo />

      <CookieConsent />
    </div>
  );
};

export default Home;
