import React, { useEffect } from "react";
import AppRoutes from "./components/routes";
import TagManager from "react-gtm-module";

const App: React.FC = () => {
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-TJNQXTVS",
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  return <AppRoutes />;
};

export default App;
