import React from "react";
import Hero from "../components/Hero/Hero";
import Plinko from "../components/Plinko/Plinko";
import OnlineCasinosInfo from "../components/OnlineCasinoInfo/OnlineCasinoInfo";
import CookieConsent from "../components/Cookie/Cookie";

const Onlinecasino = () => {
  return (
    <div>
      <Hero
        title={"Os 10 Melhores Casinos Online de Portugal para Jogar Plinko"}
      />
      <Plinko />
      <OnlineCasinosInfo />

      <CookieConsent />
    </div>
  );
};

export default Onlinecasino;
