import React from 'react';
import { navLinks } from '../navLinks';

const Footer: React.FC = () => {
    return (
        <footer className=" text-white py-8 mt-10">
            <div className="container  mx-auto text-center space-y-6">
                <div className="flex items-center justify-between px-6">
                    <div className="flex  flex-col items-start gap-5">

                        {/* Logo */}
                        <div className="flex justify-center">
                            <img
                                src="/logo.svg"
                                alt="Logo"
                                className="h-12"
                            />
                        </div>

                    </div>
                    {/* Navigation Links */}
                    <nav className="flex justify-center space-x-2 flex-col sm:flex-row gap-3 text-sm font-medium">
                        {
                            navLinks.map((link: any, index: number) => (
                                <a href={link.path} key={index} className="hover:text-yellow-400">{link.name}</a>
                            ))
                        }
                    </nav>
                </div>

                {/* Social Media Links */}
                <div className="mt-4 md:mt-0 flex flex-col sm:flex-row items-center justify-center space-x-6">
                    <a
                        href="https://www.gamcare.org.uk/"
                        className="text-gray-300 hover:text-blue-500 transition"
                    >
                        <img
                            src="/images/gamcare.svg"
                            alt="stodlinjen-logo"
                            width={100}
                            height={100}
                        />
                    </a>
                    <a href="/" className="text-gray-400 hover:text-blue-500 transition">
                        <img
                            src="/images/18+disclaimer.svg"
                            alt="18+-logo"
                            width={50}
                            height={50}
                        />
                    </a>
                    <a
                        href="https://www.gambleaware.org/"
                        className="text-gray-400 hover:text-blue-500 transition"
                    >
                        <img
                            src="/images/gamblingaware.svg"
                            alt="spelpaus-logo"
                            width={200}
                            height={200}
                        />
                    </a>
                </div>
            </div>

            {/* Copyright */}
            <p className="text-sm text-gray-400 text-center mt-4">
                &copy; {new Date().getFullYear()} Top Casino Reviews
            </p>
        </footer>
    );
};

export default Footer;
