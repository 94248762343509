// navLinks.ts

interface NavLink {
  name: string;
  path: string;
}

export const navLinks: NavLink[] = [
  { name: "Início", path: "/" },
  { name: "Plinko", path: "/plinko" },
  { name: "Slots", path: "/slots" },
  { name: "Privacidade", path: "/privacy" },
  { name: "Termos", path: "/terms" },
  { name: "Jogo Responsável", path: "/responsible-game" },
  { name: "Cookies", path: "/cookie" },
  { name: "Entre em Contato", path: "/contact" },
];
