import React from "react";
import Hero from "../components/Hero/Hero";
import Slots from "../components/Slots/Slots";
import OnlineCasinosInfo from "../components/OnlineCasinoInfo/OnlineCasinoInfo";
import CookieConsent from "../components/Cookie/Cookie";

const Onlinecasino = () => {
  return (
    <div>
      <Hero title={"Melhores Sites de Slots Online em Portugal"} />
      <Slots />
      <OnlineCasinosInfo />

      <CookieConsent />
    </div>
  );
};

export default Onlinecasino;
