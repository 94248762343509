import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
import Privacy from "../pages/Privacy";
import Terms from "../pages/Terms";
import CookiePolicy from "../pages/Cookie";
import ResponsibleGame from "../pages/Responsible-game";
import ContactPage from "../pages/Contact";
import Onlinecasino from "../pages/Onlinecasino";
import Plinko from "../pages/Plinko";

import Slots from "../pages/Slots";

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/onlinecasino" element={<Onlinecasino />} />
        <Route path="/plinko" element={<Plinko />} />
        <Route path="/slots" element={<Slots />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/responsible-game" element={<ResponsibleGame />} />
        <Route path="/cookie" element={<CookiePolicy />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
